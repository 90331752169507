<template>
  <div
    class="text-left px-0"
    id="appointments"
    style="background-color: #0e0e0e !important"
  >
    <div class="schedule-bg">
      <div class="container p-0 position-relative" style="top: 60%">
        <div class="d-flex justify-content-between">
          <div
            class="font-md-32 font-20 kallisto-bold mb-0 text-left"
            id="pod-schedule"
          >
            Pod Schedule
          </div>
          <!-- <div class="d-none d-md-flex">
            <button
              v-if="
                $store.state.role == 'admin' ||
                $store.state.role == 'pod operator'
              "
              class="my-1 check-btn pl-20 pr-20 pt-10 pb-10 d-flex align-items-center"
              @click="checkInUser()"
              style="color: #ffc627; border: none"
            >
              <img
                :src="getIcon('check-in-desktop.svg')"
                alt="check-in-desktop"
                class="pr-2"
              />
              <span class="py-0 font-weight-bold" style="color: #151515"
                >Check-in</span
              >
            </button>
          </div> -->
        </div>
      </div>
    </div>
    <div class="container px-0">
      <div class="d-flex justify-content-between mb-2">
        <a class="font-14 os-semibold" style="padding-top: 1.5rem"
          >Total available seats for today:
          <span
            class="ml-2"
            style="
              padding: 5px 12px;
              border-radius: 6px;
              color: white;
              background: #ffffff1a;
            "
          >
            {{
              getTotalSeats(filterSessions($store.state.SchedulerSessions))
                .available
            }}
            of
            {{
              getTotalSeats(filterSessions($store.state.SchedulerSessions))
                .total
            }}
          </span>
        </a>
      </div>
      <!--Commenting the below code based on the design review-->
      <!-- <a class="scroll-to-acts d-none d-md-flex">
        <img
          class="pointer"
          :src="getIcon('arrow-left-solid.svg')"
          alt="arrow-left-solid"
          @click="scrollToFutureSessions()"
          style="transform: rotate(-90deg)"
          id="arrow-left-solid"
        />
      </a> -->
      <div class="row d-none d-md-block">
        <span class="col-12 d-flex p-0 m-0 justify-content-between">
          <div class="ml-3">
            <!-- confirm with instructor log in -->
            <div
              v-if="$store.state.role == 'instructor'"
              class="d-flex align-items-center mr-32"
            >
              <a
                class="cursor-pointer font-weight-bold font-14 d-flex align-items-center"
                id="dropdownMenuSeats"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style="
                  border-radius: 5px;
                  color: white !important;
                  background: #282828;
                  padding: 10px 12px 10px 18px;
                "
                >Shows
                <img
                  class="btn-icon mx-2"
                  :src="getIcon('chevron-left-solid.svg')"
                  alt="chevron-left-solid"
                  height="10"
              /></a>
              <div
                class="dropdown-menu px-2"
                aria-labelledby="dropdownMenuSeats"
                id="shows-dropdown"
              >
                <p
                  class="p-1 mb-0"
                  v-for="(mod, mo) in getModules(
                    $store.state.SchedulerSessions
                  )"
                  :key="mo"
                >
                  <label class="check-container">
                    <input
                      :id="mod"
                      type="checkbox"
                      :checked="false"
                      @change="updateModule(mod)"
                    />
                    <span class="checkmark"></span>
                    {{ getTitleData(mod).shortname }}
                  </label>
                </p>
              </div>
            </div>
            <div class="d-flex mt-1 ml-1">
              <div class="d-flex" style="padding: 0.2rem 0rem">
                <img
                  class="pr-1 my-5 mr-12"
                  :src="getIcon('calendar-alt-regular.svg')"
                  alt="calendar-alt-solid"
                  width="26px"
                  height="22px"
                />
                <datepicker
                  v-model="calendarDate"
                  :format="customFormatter"
                  :highlighted="getCurrentDate()"
                  class="session-date-selector pr-2 mt-29"
                  @closed="selectedDate(calendarDate)"
                >
                </datepicker>
              </div>
              <div class="d-flex align-items-center mt-1">
                <div
                  style="
                    background: rgb(75, 75, 75);
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                  "
                  class="justify-content-center align-items-center d-flex pointer"
                  @click="changeCurrentDate(-1)"
                >
                  <img
                    :src="getIcon('chevron-left-solid.svg')"
                    alt="chevron-left-solid"
                    class="my-2"
                    height="15"
                  />
                </div>
                <div
                  class="d-flex align-items-center mx-3 px-0"
                  style="width: 39% !important"
                >
                  <carousel
                    :per-page="3"
                    :mouse-drag="true"
                    :paginationEnabled="false"
                    :scrollPerPage="false"
                    style="width: 100% !important"
                    :navigateTo="getIndexOfSelectedDate()"
                    @panstart="handlePanStart"
                    @panend="handlePanEnd"
                  >
                    <slide
                      v-for="day in getCurrentWeek()"
                      :key="day"
                      style="
                        flex-basis: auto !important;
                        width: auto;
                        padding: 0% 2% !important;
                      "
                    >
                      <div>
                        <button
                          class="button-carousel-mobile font-weight-bold"
                          :class="[
                            isToday(day) && isDaySelected(day)
                              ? 'selected-button-carousel-mobile'
                              : isToday(day)
                              ? 'today-button-carousel-mobile'
                              : isDaySelected(day)
                              ? 'selected-button-carousel-mobile'
                              : '',
                          ]"
                          @click="setCurrentDate(day)"
                        >
                          <span
                            class="month-carousel-mobile d-block text-uppercase"
                            >{{
                              day.toLocaleString("default", { month: "short" })
                            }}</span
                          >
                          <span class="date-carousel-mobile d-block">{{
                            day
                              .toLocaleString("default", { day: "2-digit" })
                              .substr(0, 2)
                          }}</span>
                          <span
                            class="month-carousel-mobile d-block text-uppercase"
                            >{{
                              day.toLocaleString("default", {
                                weekday: "short",
                              })
                            }}</span
                          >
                        </button>
                      </div>
                    </slide>
                  </carousel>
                </div>
                <div
                  style="
                    background: rgb(75, 75, 75);
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                  "
                  class="justify-content-center align-items-center d-flex pointer"
                  @click="changeCurrentDate(1)"
                >
                  <img
                    class="rotate-180 my-2"
                    height="15"
                    :src="getIcon('chevron-left-solid.svg')"
                    alt="chevron-left-solid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 p-0 d-flex mt-32 mt-47">
            <div
              class="font-weight-bold d-flex justify-content-end mr-3"
              style="width: 100%"
              v-if="$store.state.SchedulerSessions.length > 0"
            >
              <div class="dropdown-wrapper moduleAct-corses mr-12 mt-1">
                <div @click="toggleDropdown" class="d-flex">
                  <div
                    class="moduleAct font-weight-bold text-black mb-0 font-12 font-md-14"
                  >
                    {{ selectedSubject || "All subjects" }}
                  </div>

                  <img
                    class="my-auto pr-16 pl-2"
                    src="@/assets/icons/down-chevron.svg"
                    alt=""
                  />
                </div>
                <div v-if="dropdownOpen" class="dropdown-options">
                  <ul>
                    <li
                      @click="selectOption('All subjects')"
                      class="text-white pl-20 font-md-14 os-semibold"
                    >
                      All subjects
                    </li>
                    <li
                      v-for="(tlc, i) in showOptions"
                      :key="i"
                      @click="selectOption(tlc)"
                      class="text-white pl-20 font-md-14 os-semibold"
                    >
                      {{ tlc }}
                    </li>
                  </ul>
                </div>
              </div>

              <div
                v-if="
                  $store.state.SchedulerSessions.length > 0 &&
                  ($store.state.role == 'admin' ||
                    $store.state.role == 'pod operator')
                "
                class="my-0 d-flex pt-3"
              >
                <button
                  class="filter-button-mobile font-14 font-weight-bold"
                  data-toggle="modal"
                  data-target="#desktopFilterModal"
                  style="width: 97px; height: 39px"
                >
                  <img
                    :src="getIcon('funnel-mobile.svg')"
                    alt="funnel-mobile"
                    class="pr-1"
                  />
                  Filters
                </button>

                <div
                  class="modal fade p-0"
                  id="desktopFilterModal"
                  tabindex="-1"
                  role="dialog"
                  aria-hidden="true"
                >
                  <div
                    class="modal-dialog modal-md modal-dialog-centered"
                    role="document"
                    style="width: 520px !important"
                  >
                    <div
                      class="modal-content"
                      style="
                        border: 0.5px solid #0b5786 !important;
                        box-shadow: 0px 3px 14px 0px rgba(184, 236, 255, 0.15);
                        border-radius: 12px;
                        /* height: 658px !important; */
                      "
                    >
                      <div
                        class="b-modal-header h-100 d-flex flex-column justify-content-between"
                      >
                        <div class="d-block">
                          <div
                            class="d-flex justify-content-between align-items-center py-3 px-4"
                            style="border-bottom: 0.5px solid #555555"
                          >
                            <a data-dismiss="modal" class="ml-32">
                              <img
                                height="12"
                                class="pointer x-button d-none"
                                :src="getIcon('x-button-mobile.svg')"
                                alt="x-button-mobile"
                              />
                            </a>
                            <span
                              class="d-flex filter-header-mobile kallisto-bold justify-content-center flex-grow-1"
                              style="color: #d0d0d0 !important"
                            >
                              Select filters
                            </span>
                            <a data-dismiss="modal" class="">
                              <img
                                height="12"
                                class="pointer x-button"
                                :src="getIcon('x-button-mobile.svg')"
                                alt="x-button-mobile"
                              />
                            </a>
                          </div>
                          <div id="filter-collapse-mobile" class="mt-24">
                            <div class="mb-0 px-4 pod-schedule-filter">
                              <div
                                class="mb-3"
                                style="border-bottom: 0.5px solid #ffffff66"
                              >
                                <div class="pb-3">
                                  Pod ({{ selectedPods.length }})
                                </div>
                                <div
                                  id="pod-collapse-mobile"
                                  class="collapse show"
                                >
                                  <div class="py-1 container">
                                    <div
                                      class="mb-2 row mt-0"
                                      v-for="(pod, po) in Math.ceil(
                                        getPods($store.state.SchedulerSessions)
                                          .length / 2
                                      )"
                                      :key="po"
                                    >
                                      <div class="col-6 p-0">
                                        <label class="check-container">
                                          <input
                                            :id="
                                              getPods(
                                                $store.state.SchedulerSessions
                                              )[po]
                                            "
                                            type="checkbox"
                                            :checked="
                                              selectedPods.includes(
                                                getPods(
                                                  $store.state.SchedulerSessions
                                                )[po]
                                              )
                                            "
                                            @change="
                                              updatePods(
                                                getPods(
                                                  $store.state.SchedulerSessions
                                                )[po]
                                              )
                                            "
                                          />
                                          <span class="checkmark-mobile"></span>
                                          <span
                                            ><span>Pod </span>
                                            <span class="">{{
                                              getPod(
                                                getPods(
                                                  $store.state.SchedulerSessions
                                                )[po]
                                              )
                                            }}</span></span
                                          >
                                        </label>
                                      </div>
                                      <div
                                        class="col-6 p-0"
                                        v-if="
                                          po +
                                            Math.ceil(
                                              getPods(
                                                $store.state.SchedulerSessions
                                              ).length / 2
                                            ) <
                                          getPods(
                                            $store.state.SchedulerSessions
                                          ).length
                                        "
                                      >
                                        <label class="check-container">
                                          <input
                                            :id="
                                              getPods(
                                                $store.state.SchedulerSessions
                                              )[
                                                po +
                                                  Math.ceil(
                                                    getPods(
                                                      $store.state
                                                        .SchedulerSessions
                                                    ).length / 2
                                                  )
                                              ]
                                            "
                                            type="checkbox"
                                            :checked="
                                              selectedPods.includes(
                                                getPods(
                                                  $store.state.SchedulerSessions
                                                )[
                                                  po +
                                                    Math.ceil(
                                                      getPods(
                                                        $store.state
                                                          .SchedulerSessions
                                                      ).length / 2
                                                    )
                                                ]
                                              )
                                            "
                                            @change="
                                              updatePods(
                                                getPods(
                                                  $store.state.SchedulerSessions
                                                )[
                                                  po +
                                                    Math.ceil(
                                                      getPods(
                                                        $store.state
                                                          .SchedulerSessions
                                                      ).length / 2
                                                    )
                                                ]
                                              )
                                            "
                                          />
                                          <span class="checkmark-mobile"></span>
                                          <span
                                            ><span>Pod </span>
                                            <span class="">{{
                                              getPod(
                                                getPods(
                                                  $store.state.SchedulerSessions
                                                )[
                                                  po +
                                                    Math.ceil(
                                                      getPods(
                                                        $store.state
                                                          .SchedulerSessions
                                                      ).length / 2
                                                    )
                                                ]
                                              )
                                            }}</span></span
                                          >
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="mb-3"
                                style="border-bottom: 0.5px solid #ffffff66"
                              >
                                <div class="py-3">
                                  Shows ({{ selectedActs.length }})
                                </div>
                                <div
                                  id="show-collapse-mobile"
                                  class="collapse show"
                                >
                                  <div class="py-1 container">
                                    <div
                                      class="mb-2 row mt-0"
                                      v-for="(mod, mo) in Math.ceil(
                                        getModules(
                                          $store.state.SchedulerSessions
                                        ).length / 2
                                      )"
                                      :key="mo"
                                    >
                                      <div class="col-6 p-0">
                                        <label class="check-container">
                                          <input
                                            :id="
                                              getModules(
                                                $store.state.SchedulerSessions
                                              )[mo]
                                            "
                                            type="checkbox"
                                            :checked="
                                              selectedActs.includes(
                                                getModules(
                                                  $store.state.SchedulerSessions
                                                )[mo]
                                              )
                                            "
                                            @change="
                                              updateModule(
                                                getModules(
                                                  $store.state.SchedulerSessions
                                                )[mo]
                                              )
                                            "
                                          />
                                          <span class="checkmark-mobile"></span>
                                          {{
                                            getTitleData(
                                              getModules(
                                                $store.state.SchedulerSessions
                                              )[mo]
                                            ).shortname
                                          }}
                                        </label>
                                      </div>
                                      <div
                                        class="col-6 p-0"
                                        v-if="
                                          mo +
                                            Math.ceil(
                                              getModules(
                                                $store.state.SchedulerSessions
                                              ).length / 2
                                            ) <
                                          getModules(
                                            $store.state.SchedulerSessions
                                          ).length
                                        "
                                      >
                                        <label class="check-container">
                                          <input
                                            :id="
                                              getModules(
                                                $store.state.SchedulerSessions
                                              )[
                                                mo +
                                                  Math.ceil(
                                                    getModules(
                                                      $store.state
                                                        .SchedulerSessions
                                                    ).length / 2
                                                  )
                                              ]
                                            "
                                            type="checkbox"
                                            :checked="
                                              selectedActs.includes(
                                                getModules(
                                                  $store.state.SchedulerSessions
                                                )[
                                                  mo +
                                                    Math.ceil(
                                                      getModules(
                                                        $store.state
                                                          .SchedulerSessions
                                                      ).length / 2
                                                    )
                                                ]
                                              )
                                            "
                                            @change="
                                              updateModule(
                                                getModules(
                                                  $store.state.SchedulerSessions
                                                )[
                                                  mo +
                                                    Math.ceil(
                                                      getModules(
                                                        $store.state
                                                          .SchedulerSessions
                                                      ).length / 2
                                                    )
                                                ]
                                              )
                                            "
                                          />
                                          <span class="checkmark-mobile"></span>
                                          {{
                                            getTitleData(
                                              getModules(
                                                $store.state.SchedulerSessions
                                              )[
                                                mo +
                                                  Math.ceil(
                                                    getModules(
                                                      $store.state
                                                        .SchedulerSessions
                                                    ).length / 2
                                                  )
                                              ]
                                            ).shortname
                                          }}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="mb-3"
                                style="border-bottom: 0.5px solid #ffffff66"
                              >
                                <div class="py-3">
                                  Show type ({{ getActiveAudienceCount() }})
                                </div>
                                <div
                                  id="audience-collapse-mobile"
                                  class="collapse show"
                                >
                                  <div class="py-1">
                                    <p class="mb-3">
                                      <label class="check-container">
                                        <input
                                          v-model="hiddenShows"
                                          type="checkbox"
                                          :checked="hiddenShows"
                                          @click="RF()"
                                        />
                                        <span class="checkmark-mobile"></span>
                                        Hidden shows
                                      </label>
                                    </p>
                                    <p class="mb-3">
                                      <label class="check-container">
                                        <input
                                          v-model="publicSHows"
                                          type="checkbox"
                                          :checked="publicSHows"
                                          @click="RF()"
                                        />
                                        <span class="checkmark-mobile"></span>
                                        Public shows
                                      </label>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div class="py-3">
                                  Reservation type ({{
                                    getActiveAudienceCount()
                                  }})
                                </div>
                                <div
                                  id="audience-collapse-mobile"
                                  class="collapse show"
                                >
                                  <div class="py-1">
                                    <p class="mb-3">
                                      <label class="check-container">
                                        <input
                                          v-model="typeGroup"
                                          type="checkbox"
                                          :checked="typeGroup"
                                          @click="RF()"
                                        />
                                        <span class="checkmark-mobile"></span>
                                        Group
                                      </label>
                                    </p>
                                    <p class="mb-3">
                                      <label class="check-container">
                                        <input
                                          v-model="typeIndividual"
                                          type="checkbox"
                                          :checked="typeIndividual"
                                          @click="RF()"
                                        />
                                        <span class="checkmark-mobile"></span>
                                        Individual
                                      </label>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="d-flex justify-content-center mb-32">
                                <div class="d-flex">
                                  <button
                                    @click="clearAllFilters()"
                                    class="text-center font-weight-bold font-14 reset-button-filter m-1 mr-2"
                                  >
                                    Reset filters
                                  </button>
                                  <button
                                    data-dismiss="modal"
                                    class="text-center font-weight-bold font-14 apply-filter-button-mobile m-1"
                                  >
                                    Apply ({{
                                      getAppliedFilters(
                                        $store.state.SchedulerSessions
                                      )
                                    }})
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
      </div>

      <div class="d-none d-md-block">
        <div
          v-if="$store.state.SchedulerSessions.length == 0"
          class="no-sessions p-4 font-24"
        >
          <p class="py-4">No sessions are scheduled</p>
        </div>
        <div
          v-else-if="filterSessions($store.state.SchedulerSessions).length == 0"
          class="no-sessions p-4 font-24"
        >
          <p class="py-4">
            There are currently no sessions available for the day
          </p>
        </div>
        <div
          class="border-layout mb-22 mt-12"
          v-for="(session, s) in filterSessions($store.state.SchedulerSessions)"
          :key="s"
          v-else-if="$store.state.appointmentsRefresh"
        >
          <div
            v-if="compareSessionDateWithPodSessionDate(session.start_timestamp)"
          >
            <div id="accordion-desktop">
              <div
                class="card"
                style="
                  background-color: #151515 !important;
                  border: none !important;
                "
              >
                <div class="card-header p-0" :id="'headingOneDesktop' + s">
                  <h5 class="mb-0">
                    <button
                      class="accordion-heading-button-desktop text-left px-3 py-3 align-items-center"
                      :class="{ collapsed: s !== 0 }"
                      data-toggle="collapse"
                      :data-target="'#collapseOneDesktop' + s"
                      aria-expanded="true"
                      :aria-controls="'collapseOneDesktop' + s"
                      style="background-color: #242424"
                    >
                      <div class="d-flex justify-content-between">
                        <div class="col-md-5 col-lg-4 col-12">
                          <div class="d-flex">
                            <div class="p-0 col-12">
                              <div>
                                <h3 class="font-24 d-inline font-weight-bold">
                                  {{ getTitleData(session.title_id).shortname }}
                                  <img
                                    v-if="session.group_session"
                                    :src="getIcon('group.svg')"
                                    alt="group"
                                    class="ml-2"
                                  />
                                </h3>
                              </div>
                            </div>
                            <span class="mt-1">
                              <p
                                v-if="
                                  session.tag_data?.is_lab === true ||
                                  session.tag_data?.is_recitation === true
                                "
                                style="
                                  display: flex;
                                  width: 85px;

                                  border-radius: 12px;
                                  background: rgba(255, 255, 255, 0.1);
                                  justify-content: center;
                                  padding: 5px 10px;
                                  line-height: 1;
                                  font-size: 14px;
                                "
                              >
                                {{
                                  session.tag_data?.is_lab === true
                                    ? "Lab"
                                    : session.tag_data?.is_recitation === true
                                    ? "Recitation"
                                    : null
                                }}
                              </p>
                            </span>
                          </div>
                          <div class="d-flex">
                            <div
                              class="font-16 font-weight-bold d-flex"
                              style="
                                color: #d0d0d0;
                                width: 100%;
                                flex-direction: row;
                              "
                            >
                              <img
                                :src="
                                  getIcon(
                                    'clock-regular-appointment-mobile.svg'
                                  )
                                "
                                alt="clock-regular-appointment-mobile"
                                class="mr-2 mb-1"
                                style="width: 12px"
                              />
                              {{ getTrimTime(session.start_timestamp) }}

                              <div
                                class="d-flex p-0 m-0"
                                v-if="
                                  session.tag_data?.is_lab === true ||
                                  session.tag_data?.is_recitation === true
                                "
                              >
                                &nbsp; | &nbsp;
                                {{ session.tag_data?.section_id }}
                              </div>
                              <div v-if="session.pod_id" class="d-flex">
                                &nbsp; | &nbsp;
                                <div>Pod &nbsp;</div>
                                <div class="pod-num">
                                  {{ getPod(session.pod_id) }}
                                </div>
                              </div>
                              <div class="d-flex">
                                <cancel-session
                                  v-if="
                                    !$store.state.loader &&
                                    ($store.state.role == 'admin' ||
                                      $store.state.role == 'pod operator')
                                  "
                                  :session="session"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="d-flex mt-1">
                            <span
                              v-if="
                                session.show_time_description == 'public show'
                              "
                              class="font-12 font-weight-bold btn-white p-1 px-2"
                              style="
                                border: 1px solid #007dc5;
                                border-radius: 4px;
                              "
                            >
                              Public
                            </span>
                            <span
                              v-else-if="
                                session.show_time_description == 'hidden show'
                              "
                              class="font-12 font-weight-bold btn-white p-1 px-2"
                              style="
                                border: 1px solid #ffc627;
                                border-radius: 4px;
                              "
                            >
                              Private</span
                            >
                            <span
                              v-else
                              class="font-12 font-weight-bold btn-white p-1 px-2"
                            >
                              {{ session.show_time_description }}</span
                            >
                            <a
                              class="table-header mx-2 font-14 os-semibold"
                              style="
                                border-radius: 6px;
                                text-decoration: none;
                                padding: 5px 12px;
                              "
                            >
                              {{ session.seats_available }} of
                              {{ session.max_seats }} seats left</a
                            >
                          </div>
                        </div>
                        <div class="col-1 d-flex justify-content-end">
                          <div
                            class="d-flex mt-3 justify-content-center align-items-center"
                            style="
                              background: #4b4b4b;
                              width: 30px;
                              height: 30px;
                              border-radius: 50%;
                            "
                          >
                            <img
                              :src="getIcon('accordion-down-arrow.svg')"
                              alt="accordion-down-arrow"
                              class="rotate-icon"
                              style="width: fit-content"
                            />
                          </div>
                        </div>
                      </div>
                    </button>
                  </h5>
                </div>
                <div
                  :id="'collapseOneDesktop' + s"
                  class="collapse"
                  :class="{ show: s === 0 }"
                  :aria-labelledby="'headingOneDesktop' + s"
                  data-parent="#accordion-desktop"
                >
                  <div class="card-body py-0 px-0" style="">
                    <div class="font-14 mb-1 tableScroll">
                      <table class="col-12 mb-0" style="">
                        <tr class="table-header d-flex pl-3">
                          <th class="col-3 py-md-2 p-0 px-md-4">Name</th>
                          <th class="col-lg-3 col-5 py-md-2 p-0">Email</th>
                          <th class="col-2 py-md-2 p-0">User Type</th>
                          <th class="col-2 py-md-2 p-0">Status</th>
                          <th
                            class="col-2 py-md-2 p-0"
                            v-if="
                              $store.state.role == 'admin' ||
                              $store.state.role == 'pod operator'
                            "
                          >
                            Cancel
                          </th>
                        </tr>
                        <participants
                          v-if="!$store.state.loader"
                          :session="session"
                        />
                        <tr
                          class="d-flex mb-2 ml-3"
                          v-if="
                            session.seats_available != 0 &&
                            available(session) &&
                            ($store.state.role == 'admin' ||
                              $store.state.role == 'pod operator')
                          "
                        >
                          <td
                            class="py-2 font-weight-bold mt-2"
                            style="cursor: pointer"
                          >
                            <a
                              @click="addUser(session)"
                              class="add-user-btn autovip-checkin mx-2 font-weight-bold"
                              style="color: #ffffff"
                              >+ Add user</a
                            >
                            <a
                              v-if="
                                session.seats_available != 0 &&
                                available(session) &&
                                $store.state.role == 'admin'
                              "
                              @click="autoVIPCheckIn(session)"
                              class="autovip-checkin"
                            >
                              Add VIP Guest
                            </a>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="px-4">No sessions are scheduled</div>
        </div>
      </div>

      <!-- Below for mobile view -->
      <div class="row mb-3 mx-0 d-block d-md-none">
        <span class="col-12 row p-0 mb-2 mx-0">
          <div class="col-12 d-flex px-0 px-md-3 justify-content-between">
            <div
              v-if="$store.state.role == 'instructor'"
              class="d-flex align-items-center mr-3"
            >
              <a
                class="cursor-pointer font-weight-bold font-14 d-flex align-items-center"
                id="dropdownMenuSeats"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style="
                  border-radius: 5px;
                  color: white !important;
                  background: #282828;
                  padding: 6px 12px;
                "
                >Shows
                <img
                  class="btn-icon mx-2"
                  :src="getIcon('chevron-left-solid.svg')"
                  alt="chevron-left-solid"
                  height="10"
              /></a>
              <div
                class="dropdown-menu px-2"
                aria-labelledby="dropdownMenuSeats"
                id="shows-dropdown"
              >
                <p
                  class="p-1 mb-0"
                  v-for="(mod, mo) in getModules(
                    $store.state.SchedulerSessions
                  )"
                  :key="mo"
                >
                  <label class="check-container">
                    <input
                      :id="mod"
                      type="checkbox"
                      :checked="false"
                      @change="updateModule(mod)"
                    />
                    <span class="checkmark"></span>
                    {{ getTitleData(mod).shortname }}
                  </label>
                </p>
              </div>
            </div>
            <div class="d-flex" style="margin: 0rem 0.5rem 0rem 1rem">
              <img
                class="pointer"
                :src="getIcon('calendar-alt-regular.svg')"
                alt="calendar-alt-solid"
                width="25px"
              />
              <datepicker
                v-model="calendarDate"
                :format="customFormatter"
                :highlighted="getCurrentDate()"
                class="session-date-selector session-date-selector-mobile"
                @closed="selectedDate(calendarDate)"
              >
              </datepicker>
            </div>
            <div
              class="d-flex align-items-center mr-2 px-0"
              style="width: 68% !important"
            >
              <carousel
                :per-page="4"
                :mouse-drag="true"
                :paginationEnabled="false"
                :scrollPerPage="false"
                :navigateTo="getIndexOfSelectedDate()"
                style="width: 100% !important"
                @panstart="handlePanStart"
                @panend="handlePanEnd"
              >
                <slide
                  v-for="day in getCurrentWeek()"
                  :key="day"
                  style="
                    flex-basis: auto !important;
                    width: auto;
                    padding: 0% 2% !important;
                  "
                >
                  <div>
                    <button
                      class="button-carousel-mobile"
                      :class="[
                        isToday(day) && isDaySelected(day)
                          ? 'selected-button-carousel-mobile'
                          : isToday(day)
                          ? 'today-button-carousel-mobile'
                          : isDaySelected(day)
                          ? 'selected-button-carousel-mobile'
                          : '',
                      ]"
                      @click="setCurrentDate(day)"
                    >
                      <span
                        class="month-carousel-mobile d-block text-uppercase"
                        >{{
                          day.toLocaleString("default", { month: "short" })
                        }}</span
                      >
                      <span class="date-carousel-mobile d-block">{{
                        day
                          .toLocaleString("default", { day: "2-digit" })
                          .substr(0, 2)
                      }}</span>
                      <span
                        class="month-carousel-mobile d-block text-uppercase"
                        >{{
                          day.toLocaleString("default", { weekday: "short" })
                        }}</span
                      >
                    </button>
                  </div>
                </slide>
              </carousel>
            </div>
            <button
              v-if="
                $store.state.role == 'admin' ||
                $store.state.role == 'pod operator'
              "
              class="m-0 mr-3"
              @click="checkInUser()"
              style="border: none; margin: 0"
            >
              <img
                :src="getIcon('qr-code-mobile.svg')"
                alt="qr-code-mobile"
                class=""
              />
            </button>
          </div>
          <div class="row pt-4 pb-1 mx-0">
            <!-- filters -->
            <div
              v-if="
                $store.state.SchedulerSessions.length > 0 &&
                ($store.state.role == 'admin' ||
                  $store.state.role == 'pod operator')
              "
              class="col-3 my-3 d-flex justify-content-left py-2 pl-3 pr-0"
              style=""
            >
              <button
                class="filter-button-mobile font-14 font-weight-bold p-0 pt-8 pb-8 px-12"
                data-toggle="modal"
                data-target="#mobileFilterModal"
                style="height: 35px; width: 84px; line-height: 1"
              >
                Filters
                <img
                  :src="getIcon('funnel-mobile.svg')"
                  alt="funnel-mobile"
                  class="pl-2"
                />
              </button>

              <div
                class="modal fade p-0"
                id="mobileFilterModal"
                tabindex="-1"
                role="dialog"
                aria-hidden="true"
              >
                <div
                  class="modal-dialog modal-dialog-slideout modal-md"
                  role="document"
                  style="margin: 0 auto 0 0 !important; width: 520px !important"
                >
                  <div class="modal-content">
                    <div
                      class="b-modal-header py-3 h-100 d-flex flex-column justify-content-between"
                    >
                      <div class="d-block p-0">
                        <div class="d-flex justify-content-between px-3">
                          <span
                            class="d-flex filter-header-mobile kallisto-bold"
                          >
                            Select filters
                          </span>
                          <a data-dismiss="modal" class="text-right">
                            <img
                              class="pointer"
                              :src="getIcon('x-button-mobile.svg')"
                              alt="x-button-mobile"
                            />
                          </a>
                        </div>
                        <!-- filter content -->
                        <div id="filter-collapse-mobile" class="mt-24">
                          <div class="mb-0">
                            <div
                              class="mb-3"
                              style="border-bottom: 0.5px solid #93751f"
                            >
                              <div
                                class="collapse-heading collapsed mb-3 font-weight-bold px-3"
                                data-toggle="collapse"
                                href="#pod-collapse-mobile"
                                style="
                                  width: 100% !important;
                                  cursor: pointer !important;
                                "
                              >
                                <a
                                  @click="togglePodFilterCollapseMobile"
                                  class="d-flex justify-content-between"
                                  data-toggle="collapse"
                                  href="#pod-collapse-mobile"
                                  role="button"
                                  aria-expanded="true"
                                  aria-controls="pod-collapse-mobile"
                                  style="
                                    color: #ffffff !important;
                                    background-color: #151515 !important;
                                  "
                                >
                                  Pod
                                  <img
                                    class="text-right"
                                    :src="filterPodCollapseMobile()"
                                    alt="filter-collapse-mobile"
                                  />
                                </a>
                              </div>
                              <div
                                id="pod-collapse-mobile"
                                class="collapse show"
                              >
                                <div class="py-1 px-3">
                                  <p
                                    class="mb-0"
                                    v-for="(pod, po) in getPods(
                                      $store.state.SchedulerSessions
                                    )"
                                    :key="po"
                                  >
                                    <label class="check-container">
                                      <input
                                        :id="pod"
                                        type="checkbox"
                                        :checked="selectedPods.includes(pod)"
                                        @change="updatePods(pod)"
                                      />
                                      <span class="checkmark-mobile"></span>
                                      <span
                                        ><span>Pod </span>
                                        <span class="">{{
                                          getPod(pod)
                                        }}</span></span
                                      >
                                    </label>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              class="mb-3"
                              style="border-bottom: 0.5px solid #93751f"
                            >
                              <div
                                class="collapse-heading collapsed mb-3 font-weight-bold px-3"
                                data-toggle="collapse"
                                href="#show-collapse-mobile"
                                style="
                                  width: 100% !important;
                                  cursor: pointer !important;
                                "
                              >
                                <a
                                  @click="toggleShowFilterCollapseMobile"
                                  class="d-flex justify-content-between"
                                  data-toggle="collapse"
                                  href="#show-collapse-mobile"
                                  role="button"
                                  aria-expanded="true"
                                  aria-controls="show-collapse-mobile"
                                  style="
                                    color: #ffffff !important;
                                    background-color: #151515 !important;
                                  "
                                >
                                  Shows
                                  <img
                                    class="text-right"
                                    :src="filterShowCollapseMobile()"
                                    alt="filter-collapse-minus-mobile"
                                  />
                                </a>
                              </div>
                              <div
                                id="show-collapse-mobile"
                                class="collapse show"
                              >
                                <div class="py-1 px-3">
                                  <p
                                    class="mb-0"
                                    v-for="(mod, mo) in getModules(
                                      $store.state.SchedulerSessions
                                    )"
                                    :key="mo"
                                  >
                                    <label class="check-container">
                                      <input
                                        :id="mod"
                                        type="checkbox"
                                        :checked="selectedActs.includes(mod)"
                                        @change="updateModule(mod)"
                                      />
                                      <span class="checkmark-mobile"></span>
                                      {{ getTitleData(mod).shortname }}
                                    </label>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="mb-3">
                              <div
                                class="collapse-heading collapsed mb-3 font-weight-bold px-3"
                                data-toggle="collapse"
                                href="#audience-collapse-mobile"
                                style="
                                  width: 100% !important;
                                  cursor: pointer !important;
                                "
                              >
                                <a
                                  @click="toggleAudienceFilterCollapseMobile"
                                  class="d-flex justify-content-between"
                                  data-toggle="collapse"
                                  href="#audience-collapse-mobile"
                                  role="button"
                                  aria-expanded="true"
                                  aria-controls="audience-collapse-mobile"
                                  style="
                                    color: #ffffff !important;
                                    background-color: #151515 !important;
                                  "
                                >
                                  Show type
                                  <img
                                    class="text-right"
                                    :src="filterAudienceCollapseMobile()"
                                    alt="filter-collapse-minus-mobile"
                                  />
                                </a>
                              </div>
                              <div
                                id="audience-collapse-mobile"
                                class="collapse show"
                              >
                                <div class="py-1 px-3">
                                  <p class="mb-0">
                                    <label class="check-container">
                                      <input
                                        v-model="hiddenShows"
                                        type="checkbox"
                                        :checked="hiddenShows"
                                        @click="RF()"
                                      />
                                      <span class="checkmark-mobile"></span>
                                      Hidden shows
                                    </label>
                                  </p>
                                  <p class="mb-0">
                                    <label class="check-container">
                                      <input
                                        v-model="publicSHows"
                                        type="checkbox"
                                        :checked="publicSHows"
                                        @click="RF()"
                                      />
                                      <span class="checkmark-mobile"></span>
                                      Public shows
                                    </label>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center mb-3">
                        <div class="d-flex">
                          <button
                            @click="clearAllFilters()"
                            class="text-center font-weight-bold font-14 clear-filter-button-mobile m-1 mr-2"
                          >
                            Clear all
                          </button>
                          <button
                            data-dismiss="modal"
                            class="text-center font-weight-bold font-14 apply-filter-button-mobile m-1"
                          >
                            Apply ({{
                              getAppliedFilters($store.state.SchedulerSessions)
                            }})
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="px-1"></div>
            <!-- available seats -->
            <div class="col-9 p-0 d-flex justify-content-end mt-0 pt-2 pr-3">
              <div
                class="font-weight-bold pt-4 pb-3"
                v-if="
                  $store.state.SchedulerSessions &&
                  $store.state.SchedulerSessions.length > 0
                "
              >
                <a class="font-12"
                  >Available seats for today:
                  <span
                    class="table-header ml-2"
                    style="
                      padding: 5px 12px;
                      border-radius: 6x;
                      color: white;
                      border-radius: 4px;
                    "
                  >
                    {{
                      getTotalSeats(
                        filterSessions($store.state.SchedulerSessions)
                      ).available
                    }}
                    of
                    {{
                      getTotalSeats(
                        filterSessions($store.state.SchedulerSessions)
                      ).total
                    }}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </span>
        <div
          v-if="$store.state.SchedulerSessions.length == 0"
          class="no-sessions p-4 font-24"
        >
          <p class="py-4">No sessions are scheduled</p>
        </div>
        <div
          v-else-if="filterSessions($store.state.SchedulerSessions).length == 0"
          class="no-sessions p-4 font-24"
        >
          <p class="py-4">
            There are currently no sessions available for the day
          </p>
        </div>
        <div
          id="accordion-mobile"
          v-else-if="$store.state.appointmentsRefresh"
          class=""
        >
          <div
            v-for="(pod, po) in getPods(
              filterSessions($store.state.SchedulerSessions)
            )"
            :key="po"
          >
            <div
              class="card"
              style="
                background-color: #151515 !important;
                border: none !important;
              "
            >
              <div
                class="card-header p-0"
                :id="'headingOne' + po"
                style="
                  border-bottom: 1px solid #132732 !important;
                  border-radius: 0% !important;
                "
              >
                <h5 class="mb-0">
                  <button
                    class="accordion-heading-button text-left px-3 py-2 align-items-center"
                    :class="{ collapsed: po !== 0 }"
                    data-toggle="collapse"
                    :data-target="'#collapseOne' + po"
                    aria-expanded="true"
                    :aria-controls="'collapseOne' + po"
                  >
                    <span class="font-weight-bold font-14" style="">
                      Pod {{ getPod(pod) }}
                    </span>
                    <span class="accicon">
                      <img
                        :src="getIcon('accordion-down-arrow.svg')"
                        alt="accordion-down-arrow"
                        class="rotate-icon mr-3"
                      />
                    </span>
                  </button>
                </h5>
              </div>

              <div
                :id="'collapseOne' + po"
                class="collapse"
                :class="{ show: po === 0 }"
                :aria-labelledby="'headingOne' + po"
                data-parent="#accordion-mobile"
              >
                <div class="card-body p-0" style="background: #242424">
                  <div
                    v-for="(session, s) in filterSessions(
                      $store.state.SchedulerSessions
                    )"
                    :key="s"
                  >
                    <div
                      v-if="
                        compareSessionDateWithPodSessionDate(
                          session.start_timestamp
                        )
                      "
                    >
                      <div
                        v-if="session.pod_id === pod"
                        style="
                          border-bottom: 1px solid #d0d0d040 !important;
                          border-radius: 0% !important;
                        "
                      >
                        <div style="width: 100%">
                          <button
                            class="collapse-user-details-header d-flex justify-content-between px-3 pt-3 pb-20"
                            type="button"
                            @click="
                              toggleCollapsedUserDetails(
                                session.pod_id,
                                session.session_id
                              )
                            "
                            data-toggle="collapse"
                            :data-target="'#collapse-user-details-mobile' + s"
                            aria-expanded="false"
                            :aria-controls="'collapse-user-details-mobile' + s"
                          >
                            <div class="m-0 text-left">
                              <p class="font-weight-bold font-16 m-0">
                                {{ getTitleData(session.title_id).shortname }}
                              </p>
                              <p
                                class="m-0 text-left d-flex align-items-center mt-1"
                              >
                                <span
                                  v-if="
                                    session.show_time_description ==
                                    'public show'
                                  "
                                  class="font-12 font-weight-bold btn-white px-2 justify-content-center d-flex"
                                  style="width: 52px; padding: 3px"
                                >
                                  Public
                                </span>
                                <span
                                  v-else-if="
                                    session.show_time_description ==
                                    'hidden show'
                                  "
                                  class="font-12 font-weight-bold btn-gold px-2 justify-content-center d-flex"
                                  style="width: 52px; padding: 3px"
                                >
                                  Private
                                </span>
                                <span
                                  v-else
                                  class="font-12 font-weight-bold btn-white px-2 justify-content-center d-flex"
                                  style="width: 52px; padding: 3px"
                                >
                                  {{ session.show_time_description }}
                                </span>
                                <img
                                  :src="
                                    getIcon(
                                      'clock-regular-appointment-mobile.svg'
                                    )
                                  "
                                  alt="clock-regular-appointment-mobile"
                                  class="ml-10"
                                />
                                <span
                                  class="font-weight-bold font-12 pl-1"
                                  style="color: #d0d0d0 !important"
                                >
                                  {{ getTrimTime(session.start_timestamp) }}
                                </span>
                              </p>
                              <p class="m-0 text-left mt-10">
                                <a
                                  class="table-header font-12 font-weight-bold"
                                  style="
                                    border-radius: 4px;
                                    text-decoration: none;
                                    padding: 4px 10px;
                                  "
                                >
                                  {{ session.seats_available }} of
                                  {{ session.max_seats }} seats left</a
                                >
                              </p>
                            </div>
                            <div class="mr-3">
                              <img
                                :src="
                                  getCollapsedUserDetails(
                                    session.pod_id,
                                    session.session_id
                                  )
                                "
                                alt="collapse-plus"
                                class="collapse-icons"
                              />
                            </div>
                          </button>
                        </div>
                        <div
                          class="collapse"
                          :id="'collapse-user-details-mobile' + s"
                        >
                          <div
                            class=""
                            style="background-color: #151515 !important"
                          >
                            <participants
                              v-if="!$store.state.loader"
                              :session="session"
                            />
                          </div>
                          <div
                            class="d-flex"
                            v-if="
                              session.seats_available != 0 &&
                              available(session) &&
                              ($store.state.role == 'admin' ||
                                $store.state.role == 'pod operator')
                            "
                            style="background-color: #151515 !important"
                          >
                            <div
                              class="py-2 my-2 font-weight-bold font-12"
                              style="cursor: pointer"
                            >
                              <a
                                @click="addUser(session)"
                                class="add-user-btn ml-3 autovip-checkin-mobile"
                              >
                                + Add User</a
                              >
                              <a
                                v-if="
                                  session.seats_available != 0 &&
                                  available(session) &&
                                  $store.state.role == 'admin'
                                "
                                @click="autoVIPCheckIn(session)"
                                class="autovip-checkin-mobile"
                              >
                                Add VIP Guest
                              </a>
                            </div>
                          </div>
                          <div
                            style="
                              width: 100% !important;
                              background-color: #242424 !important;
                            "
                            class="d-flex justify-content-center align-items-center"
                          >
                            <cancel-session
                              v-if="
                                !$store.state.loader &&
                                ($store.state.role == 'admin' ||
                                  $store.state.role == 'pod operator')
                              "
                              :session="session"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="px-4">No sessions are scheduled</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal fade px-0"
            id="import-shows"
            data-backdrop="exampleModalLabel"
            data-keyboard="false"
            tabindex="-1"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-dialog-centered modal-lg mx-0 mx-md-auto"
            >
              <div class="modal-content animate-bottom">
                <div class="p-0 modal-body p-5" style="min-height: 400px">
                  <div class="d-flex justify-content-between">
                    <h2 class="font-weight-bold"><a>Import shows</a></h2>
                    <button
                      data-dismiss="modal"
                      style="border: none"
                      class="p-2"
                    ></button>
                  </div>
                  <div class="mt-4 pt-3 text-center">
                    <label class="mt-4" v-if="showfileupload">
                      <input
                        id="fileUpload"
                        accept=".csv"
                        type="file"
                        ref="inputFile"
                        @input="setUploadFile()"
                        style="display: none"
                      />
                      <img
                        class="p-2"
                        :src="getIcon('download-solid-white.svg')"
                        alt="check-solid"
                      />
                      <a
                        class="cursor-pointer font-24 text-lightt font-weight-bold mb-2"
                        style="text-decoration: underline"
                        >Click here to Upload a file</a
                      >
                      <p class="font-14 text-muted mb-0">
                        Supported file type: CSV
                      </p>

                      <div class="d-flex justify-content-center mt-2 pt-5">
                        <button
                          class="mx-2 py-2 px-4"
                          data-dismiss="modal"
                          style="border: 1px solid"
                        >
                          Cancel
                        </button>
                        <button class="mx-2 py-2 px-4 btn-disabled">
                          <a>Import</a>
                        </button>
                      </div>
                    </label>
                    <div v-else>
                      <div
                        class="p-3"
                        v-if="
                          fileStatus &&
                          fileStatus.inValidData &&
                          fileStatus.inValidData.length > 0
                        "
                      >
                        <a class="font-weight-bold font-24">Error:</a>
                        <p
                          class="font-20 font-weight-bold"
                          v-for="(errorMsg, em) in fileStatus.inValidData"
                          :key="em"
                        >
                          {{ errorMsg.message }}
                        </p>
                        <label class="mt-4">
                          <input
                            id="fileUpload"
                            accept=".csv"
                            type="file"
                            ref="inputFile"
                            @input="setUploadFile()"
                            style="display: none"
                          />
                          <a class="cursor-pointer">Upload a new file again</a>
                          <div class="d-flex justify-content-center mt-1 pt-5">
                            <button
                              class="mx-2 py-2 px-4"
                              data-dismiss="modal"
                              style="border: 1px solid"
                            >
                              Cancel
                            </button>
                            <button class="mx-2 py-2 px-4 btn-disabled">
                              <a>Import</a>
                            </button>
                          </div>
                        </label>
                      </div>
                      <div v-else class="text-center">
                        <div v-if="index > 0" class="my-2 font-20">
                          <p class="font-24 font-weight-bold">
                            Importing shows...
                          </p>
                          <p class="font-18">
                            {{ index }} of {{ fileStatus.data.length }}
                          </p>
                        </div>
                        <p class="font-24" v-else>
                          Your file was successfully validated!
                        </p>
                        <div class="d-flex justify-content-center mt-1 pt-5">
                          <button
                            class="mx-2 py-2 px-4"
                            data-dismiss="modal"
                            style="border: 1px solid"
                          >
                            Cancel
                          </button>
                          <button
                            class="mx-2 py-2 px-4"
                            v-if="importEnabled"
                            @click="importShows(fileStatus.data)"
                          >
                            <a>Import</a>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import getExpierence from "../mixins/getSessionMixin";
import ClickOutside from "vue-click-outside";
import participants from "./participants";
import cancelSession from "./cancelSession.vue";
import { API } from "aws-amplify";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import CSVFileValidator from "csv-file-validator";
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    participants,
    cancelSession,
    Datepicker,
    Carousel,
    Slide,
  },
  mixins: [getExpierence],
  data() {
    return {
      showOptions: [],
      dropdownOpen: false,
      publicSHows: false,
      hiddenShows: false,
      typeGroup: false,
      typeIndividual: false,
      selectedPods: [],
      selectedActs: [],
      showfileupload: true,
      importEnabled: false,
      fileStatus: null,
      disabledDates: {
        to: new Date(Date.now() - 8640000),
        days: [6, 0], // Disable Saturday's and Sunday's
      },
      sessionid: "",
      calendarDate: null,
      openCancelBtn: false,
      refresh: true,
      listActPods: [],
      index: 0,
      isMouseDragging: false,
      isPodFilterCollapsedMobile: false,
      isShowFilterCollapsedMobile: false,
      isAudienceFilterCollapsedMobile: false,
      clearFiltersBackup: {
        isPublicShows: true,
        isHiddenShows: true,
        unselectedPods: [],
        unseletecActs: [],
      },
      isCollapsedUserDetails: [],
      subjectOptions: [],
      selectedSubject: "All subjects",
      tlcTitleMapping: null,
    };
  },
  directives: {
    ClickOutside,
  },
  async mounted() {
    this.showOptions = this.subjectOption();
    await this.$store.dispatch("setTitles");
    let vm = this;
    $("#arrow-left-solid").click(function () {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $(".add-user-btn").offset().top - 200,
        },
        1000
      );
    });
    $("#import-shows").on("hide.bs.modal", function () {
      vm.showfileupload = true;
      vm.importEnabled = false;
      vm.fileStatus = null;
      vm.index = 0;
    });
    $("#mobileFilterModal").on("show.bs.modal", function (e) {
      vm.clearFiltersBackup.isPublicShows = vm.publicSHows;
      vm.clearFiltersBackup.isHiddenShows = vm.hiddenShows;
      vm.clearFiltersBackup.unselectedPods = [];
      for (let pod of vm.selectedPods) {
        vm.clearFiltersBackup.unselectedPods.push(pod);
      }
      vm.clearFiltersBackup.unseletecActs = [];
      for (let act of vm.selectedActs) {
        vm.clearFiltersBackup.unseletecActs.push(act);
      }
    });
  },
  methods: {
    subjectOption() {
      const options = this.$store.state.titles.map((i) => i.tlc);
      return [...new Set(options)];
    },
    selectOption(option) {
      this.selectedSubject = option;
      this.tlcMapping();
      this.dropdownOpen = false;
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    selectSubject(subject) {
      this.selectedSubject = subject;
      this.arrowUp = false;
      this.onChangeSubject();
    },
    onChangeSubject() {
      this.tlcMapping();
      this.RF();
    },
    tlcMapping(reset = false) {
      if (reset || !this.tlcTitleMapping) {
        this.tlcTitleMapping = {};
      }
      this.$store.state.titles.map((i) => {
        if (!this.tlcTitleMapping[i.tlc])
          this.tlcTitleMapping[i.tlc] = new Set();
        this.tlcTitleMapping[i.tlc].add(i.title_id);
      });
      return this.tlcTitleMapping;
    },
    tlcOptions() {
      const options = this.$store.state.titles.map((i) => i.tlc);
      return [...new Set(options)];
    },

    start_and_end(str) {
      if (str.length > 20) {
        return (
          str.substr(0, 9) + "..." + str.substr(str.length - 5, str.length)
        );
      }
      return str;
    },
    getCurrentDate() {
      return {
        dates: [
          // your date
          new Date(Date.now()),
        ],
      };
    },
    getActPods(dump) {
      let podTitles = [];
      dump.forEach((element) => {
        let tempObj = {
          gearup_id: element.gearups[0].gearup_id,
          pod_id: element.pod_id,
          capacity: element.max_users,
          titlesIds: [],
        };
        if (element.titles && element.titles.length > 0) {
          element.titles.forEach((title) => {
            tempObj.titlesIds.push(title.title_id);
          });
        }
        podTitles.push(tempObj);
      });
      this.listActPods = podTitles;
    },
    setUploadFile() {
      this.getActPods(this.$store.state.scheduler_pods[0].pods);
      this.showfileupload = false;
      this.importEnabled = false;
      this.fileStatus = null;
      const file = this.$refs.inputFile.files[0];
      if (file.type == "text/csv") {
        this.validateCSV(file);
        // Api call
      } else {
        alert("error", "The file-type doesn't belong to text/csv");
      }
    },
    isTitleIdValidate(title_id, row) {
      let status = false;
      let vm = this;
      if (title_id && row[6]) {
        vm.listActPods.forEach((pod) => {
          if (pod.gearup_id == row[6]) {
            if (pod.titlesIds.includes(title_id)) {
              status = true;
            } else {
              return false;
            }
          }
        });
      }
      return status;
    },
    isDateValid(dateString) {
      var regEx = /^\d{4}-\d{2}-\d{2}$/;
      if (!dateString.match(regEx)) return false; // Invalid format
      var d = new Date(dateString);
      var dNum = d.getTime();
      if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
      return d.toISOString().slice(0, 10) === dateString;
    },
    isCapacityValidate(capacity, row) {
      let status = false;
      let vm = this;
      if (capacity && row[5]) {
        vm.listActPods.forEach((pod) => {
          if (pod.gearup_id == row[6]) {
            if (pod.capacity >= capacity) {
              status = true;
            } else {
              return false;
            }
          }
        });
      }
      return status;
    },
    validateCSV(file) {
      let vm = this;
      const config = {
        headers: [
          {
            name: "PodNo",
            inputName: "PodNo",
            required: true,
            requiredError: function (PodNo, rowNumber, columnNumber) {
              return `${PodNo} is required in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
          {
            name: "DSLAct",
            inputName: "DSLAct",
            required: true,
            requiredError: function (DSLAct, rowNumber, columnNumber) {
              return `${DSLAct} is required in the ${rowNumber} row / ${columnNumber} column`;
            },
            dependentValidate: function (gearup_id, row) {
              return vm.isTitleIdValidate(gearup_id, row);
            },
            validateError: function (DSLAct, rowNumber, columnNumber) {
              return `Title id is not valid in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
          {
            name: "Availability",
            inputName: "Availability",
            required: true,
            requiredError: function (Availability, rowNumber, columnNumber) {
              return `${Availability} is required in the ${rowNumber} row / ${columnNumber} column`;
            },
          },

          {
            name: "Date",
            inputName: "Date",
            required: true,
            requiredError: function (Date, rowNumber, columnNumber) {
              return `${Date} is required in the ${rowNumber} row / ${columnNumber} column`;
            },
            validate: function (Date) {
              return vm.isDateValid(Date);
            },
            validateError: function (Date, rowNumber, columnNumber) {
              return `${Date} is not in valid format the ${rowNumber} row / ${columnNumber} column expected in YYYY-MM-DD`;
            },
          },
          {
            name: "Time",
            inputName: "Time",
            required: true,
            requiredError: function (Time, rowNumber, columnNumber) {
              return `${Time} is required in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
          {
            name: "Capacity",
            inputName: "Capacity",
            required: true,
            requiredError: function (Capacity, rowNumber, columnNumber) {
              return `${Capacity} is required in the ${rowNumber} row / ${columnNumber} column`;
            },
            dependentValidate: function (Capacity, row) {
              return vm.isCapacityValidate(Capacity, row);
            },
            validateError: function (DSLAct, rowNumber, columnNumber) {
              return `Capacity is not valid in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
          {
            name: "gearup_id",
            inputName: "gearup_id",
            required: true,
            requiredError: function (gearup_id, rowNumber, columnNumber) {
              return `${gearup_id} is required in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
          {
            name: "site_id",
            inputName: "site_id",
            required: true,
            requiredError: function (site_id, rowNumber, columnNumber) {
              return `${site_id} is required in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
        ],
      };
      let now = this;
      CSVFileValidator(file, config)
        .then((csvStatus) => {
          now.fileStatus = csvStatus;
          if (
            csvStatus &&
            csvStatus.inValidData &&
            csvStatus.inValidData.length == 0
          ) {
            now.importEnabled = true;
          }
          // csvStatus.data // Array of objects from file
          // csvStatus.inValidMessages // Array of error messages
        })
        .catch((err) => {
          console.log(err);
        });
    },
    reccursive(session) {
      API.put("asulink", `/scheduler/sessions`, {
        body: {
          site_id: session.site_id,
          title_id: session.DSLAct,
          schedule_date: session.Date,
          local_time: parseInt(session.Time.replace(":", "")),
          public: session.Availability === "public" ? true : false,
          gearup_id: session.gearup_id,
          capacity: parseInt(session.Capacity),
        },
      })
        .then(() => {
          if (this.fileStatus.data.length - 1 > this.index) {
            this.reccursive(this.fileStatus.data[this.index + 1]);
            this.index++;
          } else {
            this.$store.state.loader = false;
            $("#import-shows").modal("hide");
            $("#import-sucess").modal();
          }
        })
        .catch((err) => {
          if (this.fileStatus.data.length - 1 > this.index) {
            console.log(err);
            console.log("failed to upload" + this.fileStatus.data[this.index]);
            this.reccursive(this.fileStatus.data[this.index + 1]);
            this.index++;
          } else {
            this.$store.state.loader = false;
            $("#import-shows").modal("hide");
            this.$toasted.show(
              `Failed to import, check the requirements before importing`,
              {
                position: "top-right",
                duration: 5000,
              }
            );
          }
        });
    },
    async importShows(sessions) {
      this.$store.state.loader = false;
      this.reccursive(sessions[0]);
    },
    updateModule(value) {
      if (this.selectedActs.includes(value)) {
        this.selectedActs = this.selectedActs.filter((e) => e !== value);
      } else {
        this.selectedActs.push(value);
      }
      this.RF();
    },
    updatePods(value) {
      if (this.selectedPods.includes(value)) {
        this.selectedPods = this.selectedPods.filter((e) => e !== value);
      } else {
        this.selectedPods.push(value);
      }
      this.RF();
    },
    getActiveAudienceCount() {
      let count = 0;
      if (this.publicSHows) count++;
      if (this.hiddenShows) count++;
      return count;
    },
    getActiveReservationCount() {
      let count = 0;
      if (this.typeGroup) count++;
      if (this.typeIndividual) count++;
      return count;
    },
    clearAllFilters() {
      this.selectedActs = [];
      this.selectedPods = [];
      this.hiddenShows = false;
      this.publicSHows = false;
      this.typeGroup = false;
      this.typeIndividual = false;
      this.RF();
    },
    getModules(sessions) {
      let modules = [];
      sessions.forEach((element) => {
        if (!modules.includes(element.title_id)) {
          modules.push(element.title_id);
        }
      });
      return modules;
    },
    getPods(sessions) {
      let pods = [];
      sessions.forEach((element) => {
        if (!pods.includes(element.pod_id)) {
          pods.push(element.pod_id);
        }
      });
      return pods;
    },
    getTotalSeats(sessions) {
      let seats = {
        available: 0,
        total: 0,
        reserved: 0,
      };
      sessions.forEach((element) => {
        seats.available = seats.available + element.seats_available;
        seats.total = seats.total + element.max_seats;
        seats.reserved = seats.reserved + element.seats_reserved;
      });
      return seats;
    },
    getPod(pod_id) {
      let temp_pod = pod_id.split("-");
      return `${temp_pod[1]}`;
    },
    filterSessions(sessions) {
      sessions = sessions.filter(
        (act) =>
          this.selectedSubject == "All subjects" ||
          (this.tlcTitleMapping[this.selectedSubject] &&
            this.tlcTitleMapping[this.selectedSubject].has(act.title_id))
      );
      if (this.getAppliedFilters() <= 0) return sessions;
      if (this.selectedActs && this.selectedActs.length > 0) {
        sessions = sessions.filter((act) =>
          this.selectedActs.includes(act.title_id)
        );
      }
      if (this.selectedPods && this.selectedPods.length > 0) {
        sessions = sessions.filter((pod) =>
          this.selectedPods.includes(pod.pod_id)
        );
      }
      let filteredSessions = sessions;
      if (this.hiddenShows || this.publicSHows) {
        filteredSessions = filteredSessions.filter(
          (session) =>
            (this.hiddenShows &&
              session.show_time_description === "hidden show") ||
            (this.publicSHows &&
              session.show_time_description === "public show")
        );
      }
      // Filter by session type
      if (this.typeGroup || this.typeIndividual) {
        filteredSessions = filteredSessions.filter(
          (session) =>
            session.group_session === this.typeGroup ||
            !this.group_session === this.typeIndividual
        );
      }
      return filteredSessions;
    },
    available(session) {
      var today = moment().tz("America/Phoenix").format();
      if (moment(session.start_timestamp).isBefore(moment(today))) {
        return false;
      }
      return true;
    },
    compareSessionDateWithPodSessionDate(date) {
      var trimDate = date.substr(0, date.indexOf("T"));
      if (trimDate == this.$store.state.podSessionDate) {
        return true;
      } else {
        return false;
      }
    },
    selectedDate(date) {
      if (date) {
        this.$store.state.loader = true;
        this.$store.state.podSessionDate = this.customFormatter(date);
        this.updateValues();
      }
    },
    customFormatter(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    checkToday(date) {
      if (date == this.convertToDate(new Date())) {
        return "Today";
      }
      return date;
    },
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },
    changeCurrentDate(day) {
      this.$store.state.loader = true;
      let temp = moment(this.$store.state.podSessionDate, "YYYY-MM-DD").add(
        day,
        "days"
      );
      this.$store.state.podSessionDate = moment(temp).format("YYYY-MM-DD");
      this.updateValues();
    },
    updateValues() {
      let myData = {
        selected_site_id: this.$store.state.selected_site_id,
        podSessionDate: this.$store.state.podSessionDate,
      };
      this.$store
        .dispatch("setSchedulerSessions", myData)
        .then((sessions) => {
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
        });
    },
    getnextday(numOfDays) {
      let dateStr = this.$store.state.podSessionDate;
      return this.convertToDate(
        new Date(
          new Date(dateStr).setDate(new Date(dateStr).getDate() + numOfDays)
        )
      );
    },
    convertToDate(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    },
    checkInUser() {
      this.$store.state.view = "scanQR";
      this.$store.state.clickedBtn = "checkInIser";
      $("#checkin-modal").modal();
    },
    RF() {
      this.$store.state.appointmentsRefresh = false;
      setTimeout(() => {
        this.$store.state.appointmentsRefresh = true;
      }, 100);
    },
    addUser(session) {
      this.$store.state.clickedBtn == "addUser";
      this.$store.state.selected_session = session.session_id;
      this.$store.state.refresh = false;
      setTimeout(() => {
        this.$store.state.refresh = true;
      }, 2000);
      this.$store.state.view = "profile";
      $("#checkin-modal").modal();
    },
    getTrimTime(timestamp) {
      return moment(timestamp).format("hh:mm A");
    },
    getTrimDate(timestamp) {
      let temp = timestamp.split("T");
      return moment(temp[0]).format("ddd, MMM D, YYYY");
    },
    OpenCancelSession(num) {
      this.sessionid = num;
      this.openCancelBtn = !this.openCancelBtn;
    },
    hide() {
      this.openCancelBtn = false;
    },
    async CancelSession(session) {
      // API to cancel sesion
      await API.del(
        "proxy",
        `/${this.$store.state.selectedSiteForScheduler.site_id}/dsl/scheduler/session/${session.session_id}`
      )
        .then((response) => {
          let myData = {
            selected_site_id: this.$store.state.selected_site_id,
            podSessionDate: this.$store.state.podSessionDate,
          };
          this.$store.dispatch("setSchedulerSessions", myData);
        })
        .catch((err) => {
          this.$store.state.error = err.response.data;
          $("#error-checkin").modal();
        });
    },
    getCurrentWeek() {
      if (!this.isMouseDragging) {
        const dateParts = this.$store.state.podSessionDate.split("-");
        const year = parseInt(dateParts[0]);
        const month = parseInt(dateParts[1]) - 1;
        const dayy = parseInt(dateParts[2]);
        const today = new Date(year, month, dayy);
        const dayOfWeek = today.getDay();
        const startDate = new Date(today);
        startDate.setDate(today.getDate() - dayOfWeek);
        const currentWeek = [];

        for (let i = 0; i < 7; i++) {
          const currentDate = new Date(startDate);
          currentDate.setDate(startDate.getDate() + i);
          currentWeek.push(currentDate);
        }
        return currentWeek;
      }
    },
    getIndexOfSelectedDate() {
      const dateParts = this.$store.state.podSessionDate.split("-");
      const year = parseInt(dateParts[0]);
      const month = parseInt(dateParts[1]) - 1;
      const dayy = parseInt(dateParts[2]);

      const today = new Date(year, month, dayy);
      let dayOfWeek = today.getDay();
      if (dayOfWeek <= 0) {
        dayOfWeek = 0;
      } else if (dayOfWeek >= 6) {
        dayOfWeek = 4;
      } else {
        dayOfWeek -= 1;
      }
      if (window.innerWidth < 768) {
        if (dayOfWeek >= 4) dayOfWeek = 3;
      }
      return dayOfWeek;
    },
    handlePanStart() {
      this.isMouseDragging = true;
    },
    handlePanEnd() {
      this.isMouseDragging = false;
    },
    setCurrentDate(day) {
      const year = day.getFullYear();
      const month = (day.getMonth() + 1).toString().padStart(2, "0");
      const dayy = day.getDate().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${dayy}`;
      this.$store.state.podSessionDate = formattedDate;
      this.updateValues();
    },
    isDaySelected(day) {
      const currentDate = this.$store.state.podSessionDate;
      const year = day.getFullYear();
      const month = (day.getMonth() + 1).toString().padStart(2, "0");
      const dayy = day.getDate().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${dayy}`;
      const isSelected = formattedDate === currentDate;
      return isSelected;
    },
    isToday(day) {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const dayy = currentDate.getDate().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${dayy}`;

      const year2 = day.getFullYear();
      const month2 = (day.getMonth() + 1).toString().padStart(2, "0");
      const dayy2 = day.getDate().toString().padStart(2, "0");
      const dayStr = `${year2}-${month2}-${dayy2}`;

      const isSelected = dayStr === formattedDate;
      return isSelected;
    },
    togglePodFilterCollapseMobile() {
      this.isPodFilterCollapsedMobile = !this.isPodFilterCollapsedMobile;
    },
    toggleShowFilterCollapseMobile() {
      this.isShowFilterCollapsedMobile = !this.isShowFilterCollapsedMobile;
    },
    toggleAudienceFilterCollapseMobile() {
      this.isAudienceFilterCollapsedMobile =
        !this.isAudienceFilterCollapsedMobile;
    },
    filterPodCollapseMobile() {
      return this.isPodFilterCollapsedMobile
        ? require("@/assets/icons/" + "filter-collapse-plus-mobile.svg")
        : require("@/assets/icons/" + "filter-collapse-minus-mobile.svg");
    },
    filterShowCollapseMobile() {
      return this.isShowFilterCollapsedMobile
        ? require("@/assets/icons/" + "filter-collapse-plus-mobile.svg")
        : require("@/assets/icons/" + "filter-collapse-minus-mobile.svg");
    },
    filterAudienceCollapseMobile() {
      return this.isAudienceFilterCollapsedMobile
        ? require("@/assets/icons/" + "filter-collapse-plus-mobile.svg")
        : require("@/assets/icons/" + "filter-collapse-minus-mobile.svg");
    },
    toggleCollapsedUserDetails(pod_id, session_id) {
      const uniqueId = pod_id + "***" + session_id;
      if (this.isCollapsedUserDetails.includes(uniqueId)) {
        this.isCollapsedUserDetails = this.isCollapsedUserDetails.filter(
          (e) => e !== uniqueId
        );
      } else {
        this.isCollapsedUserDetails.push(uniqueId);
      }
    },
    getCollapsedUserDetails(pod_id, session_id) {
      const uniqueId = pod_id + "***" + session_id;
      if (this.isCollapsedUserDetails.includes(uniqueId)) {
        return require("@/assets/icons/" + "filter-collapse-minus-mobile.svg");
      }
      return require("@/assets/icons/" + "filter-collapse-plus-mobile.svg");
    },
    getAppliedFilters(sessions) {
      let appliedFilters =
        this.selectedPods.length +
        this.selectedActs.length +
        (this.publicSHows ? 1 : 0) +
        (this.hiddenShows ? 1 : 0) +
        (this.typeGroup ? 1 : 0) +
        (this.typeIndividual ? 1 : 0);
      return appliedFilters;
    },
    autoVIPCheckIn(session) {
      this.$store.state.clickedBtn == "autoVIPCheckIn";
      this.$store.state.selected_session = session;
      this.$store.state.selected_session_id = session.session_id;
      this.$store.state.seats_available = session.seats_available;
      this.$store.state.refresh = false;
      setTimeout(() => {
        this.$store.state.refresh = true;
      }, 2000);
      this.$store.state.view = "autoVIPCheckIn";
      $("#checkin-modal").modal();
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-button-filter {
  background: #313131 !important;
  color: #f1f1f1;
  border-radius: 8px;
  padding-right: 28px;
  padding-left: 28px;
  margin-right: 16px;
}
.dropdown-wrapper {
  position: relative;
  display: inline-block;
}

.moduleAct-corses {
  background: #fafafa !important;
  color: #151515 !important;
  padding: 4px 8px;
  border-radius: 2px;
  cursor: pointer;
}
.moduleAct-corses {
  background: #ecedee !important;
  color: #151515 !important;
  padding: 4px 0px 9px 16px;
  height: 38px;
  border-radius: 2px;
}

.dropdown-options {
  position: absolute;
  background: #2c2c2c;
  border-radius: 4px;
  z-index: 1000;
  width: 252px !important;
}

.dropdown-options ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-options li {
  padding: 8px;
  cursor: pointer;
}

.dropdown-options li:hover {
  background: #e0e0e0;
}

.dropdown-options {
  position: absolute;
  border-radius: 2px;
  z-index: 1000;
  width: 100%;
}

.dropdown-options ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-options li {
  padding: 8px;
  cursor: pointer;
}

.dropdown-options li:hover {
  background: #0e4f74;
}

.v-calendar-daily__scroll-area {
  background: #0e0e0e !important;
}
.hover-button {
  background: #2c2c2c !important;
  color: #fff !important;
}
.hover-button:hover {
  background: #0e4f74 !important;
  color: #fff !important;
}
.custom-dropdown {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #333;
  color: white;
}
.dropdown-options {
  position: absolute;
  background: #2c2c2c;
  border-radius: 2px;
  z-index: 1000;
  width: 252px !important;
  left: 0%;
  top: 48px;
}
.dropdown-option {
  padding: 10px;
  cursor: pointer;
}
.dropdown-option:hover {
  background-color: #555;
}
.pod-schedule-filter {
  overflow-y: scroll;
  height: 574px;
}
.moduleAct {
  background: #ecedee !important;
  color: #151515 !important;
  padding: 4px 0px 4px 0px;
  border-radius: 2px;
}
.schedule-bg {
  height: 209px;
  background: linear-gradient(0deg, #0e0e0e 1%, transparent 50%),
    url("../assets/images/pod-schedule2xBgImage.png");
  background-size: cover;
}
.session-date-selector {
  position: absolute !important;
}
.tableScroll {
  overflow-x: scroll;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
}
.podSessionDate {
  background: #252525;
  padding: 7px 16px !important;
  border-radius: 20px;
}
.podSessionDate:hover {
  text-decoration: none;
}
.modal-content {
  border-right: 0.1px solid #7f7f7f;
}
.modal-dialog-slideout {
  min-height: 100%;
  margin: 0 0 0 auto;
}
.modal.fade .modal-dialog.modal-dialog-slideout {
  left: -50%;
  display: flex;
  align-items: stretch;
  -webkit-box-align: stretch;
  height: 100%;
  -webkit-transition: opacity 0.1s linear, left 0.1s ease-out;
  transition: opacity 0.1s linear, left 0.1s ease-out;
}
.modal.fade.show .modal-dialog.modal-dialog-slideout {
  left: 0;
  display: flex;
  align-items: stretch;
  -webkit-box-align: stretch;
  height: 100%;
}
.modal-md {
  width: 75%;
}
.modal-backdrop {
  z-index: 990 !important;
}
</style>

<style lang="scss">
.text-lightt {
  color: white !important;
  text-decoration: underline;
}
.text-lightt:hover {
  color: #ffc627 !important;
}
.import-btn:hover,
.logout:hover {
  .hide-hover {
    display: none !important;
  }
  .show-hover {
    display: inline !important;
  }
}
.logout {
  .show-hover {
    display: none;
  }
}
.invite-participants {
  color: #151515;
  background: #fff !important;
  border-radius: 7px !important;
  padding: 5px 14px 10px 14px;
}
.import-btn {
  color: #ffc627;
  &:hover {
    color: #252525;
    background: #ffc627 !important;
  }
  .show-hover {
    display: none;
  }
}
.tableScroll::-webkit-scrollbar {
  display: none;
}
#appointments {
  button {
    border-radius: 12px;
  }
}
.table-header {
  background: #ffffff1a;
}
.yellow-checkbox {
  background: #ffc627;
  height: 17px;
  width: 17px;
  margin: 2px;
  border-radius: 3px;
}
.green-checkbox {
  background: #78be20;
  height: 17px;
  width: 17px;
  margin: 2px;
  border-radius: 3px;
}
.grey-checkbox {
  background: #ffffff1a;
  height: 17px;
  width: 17px;
  margin: 2px;
  border-radius: 3px;
}
.wheelChair {
  margin: 2px;
  img {
    height: 17px;
    width: 17px;
  }
}
.btn-gold {
  color: #ffc627;
  border: 1px solid #ffc627;
  border-radius: 4px;
}
.btn-white {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
}
.no-sessions {
  height: 100vh;
  text-align: center;
}
.caousel-date {
  width: 150px;
}
@media only screen and (max-width: 1200px) {
  .caousel-date {
    width: 120px;
  }
}
.vdp-datepicker__calendar {
  .cell.highlighted {
    background: #707070 !important;
    font-weight: bold;
  }
  .cell.selected {
    background: #ffc627 !important;
    color: #151515 !important;
    font-weight: bold !important;
  }
}
@media only screen and (max-width: 767px) {
  .schedule-bg {
    height: 90px !important;
  }
  #pod-schedule {
    margin-left: 1rem;
  }
  .caousel-date {
    width: 90px;
  }
  .grey-checkbox,
  .yellow-checkbox {
    width: 8px;
    height: 8px;
  }
  .vdp-datepicker__calendar {
    left: 0px;
  }
}
@media only screen and (max-width: 991.98px) {
  .yellow-checkbox {
    display: none;
  }
  .grey-checkbox {
    display: none;
  }
  .wheelChair {
    display: none;
  }
}
.op-40 {
  opacity: 0.4;
}
input.checkbox {
  transform: scale(1.2);
}
.scroll-to-acts {
  position: fixed;
  top: 85%;
  background: gray;
  border-radius: 50%;
  padding: 10px 12px;
  left: 90%;
  z-index: 99;
}
.btn-icon {
  transform: rotate(270deg);
  margin-top: 2px;
}
.dropdown-menu {
  background: transparent !important;
  border: none !important;
  color: white !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.border-layout-filter {
  border: 1px solid;
  border-radius: 8px;
  border-color: #d0d0d05e;
}
.border-layout {
  border: 1px solid;
  border-radius: 8px;
  border-color: #424242;
}
.btn-disabled {
  opacity: 0.5;
  cursor: auto;
}
</style>
<style>
#shows-dropdown {
  /* transform: translate3d(418px, 51px, 0px) !important; */
}
/* The container */
.check-container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border-radius: 4px;
}

.checkmark-mobile {
  position: absolute;
  top: 1px !important;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #151515;
  border: 1px solid #0e4f74;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.check-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.check-container:hover input ~ .checkmark-mobile {
  background-color: #3c3c3c;
}

/* When the checkbox is checked, add a blue background */
.check-container input:checked ~ .checkmark {
  background-color: #ffc627;
  border-radius: 4px;
}

.check-container input:checked ~ .checkmark-mobile {
  background-color: #ffc627;
  border-radius: 4px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkmark-mobile:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
  display: block;
}

.check-container input:checked ~ .checkmark-mobile:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}
.check-container .checkmark-mobile:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.check-btn {
  background-color: #ffffff !important;
  border-radius: 7px !important;
  box-shadow: 0px 3px 6px #00000081 !important;
}
.import-btn {
  padding: 12px 29px !important;
  border-radius: 30px !important;
}
.pod-num {
  background: #0e4f74;
  color: #ffffff;
  border-radius: 50%;
  padding: 3px 4px;
  font-size: 14px;
}
a:hover {
  text-decoration: none !important;
}
.button-carousel-mobile {
  width: 50px !important;
  height: 55px !important;
  background-color: #272727 !important;
  color: #5b5b5b !important;
  border: 0.5px solid #272727 !important;
  border-radius: 5px !important;
}
.month-carousel-mobile {
  font-size: 9px;
}
.date-carousel-mobile {
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
}
.selected-button-carousel-mobile {
  background-color: #ffc627 !important;
  color: #151515 !important;
  border: 0.5px solid #ffc627 !important;
  border-radius: 5px !important;
}
.today-button-carousel-mobile {
  background-color: #313131 !important;
  color: #d0d0d0 !important;
  border: 0.5px solid #ffffff !important;
  border-radius: 5px !important;
}
/* Calendar button and icon were on different position */
.session-date-selector-mobile input {
  margin: 0 !important;
  padding: 0 !important;
}
.session-date-selector input {
  margin: 0 !important;
  padding: 0 !important;
}
.filter-button-mobile {
  width: 77px;
  height: 32px;
  background-color: #282828 !important;
  color: #ffffff !important;
  border: 0.3px solid #d0d0d026 !important;
  border-radius: 5px !important;
}
.filter-header-mobile {
  font-size: 18px;
  color: #ffc627;
}
.apply-filter-button-mobile {
  width: 136px !important;
  height: 42px !important;
  background-color: #ffc627 !important;
  color: #000;
  border: 0.5px solid #ffc627 !important;
  border-radius: 8px !important;
}
.clear-filter-button-mobile {
  width: 136px !important;
  height: 42px !important;
  background-color: #151515 !important;
  color: #ffffff;
  border: 0.5px solid #ffffff !important;
  border-radius: 8px !important;
}
@media only screen and (max-width: 767px) {
  .clear-filter-button-mobile,
  .apply-filter-button-mobile {
    height: 35px !important;
    width: 106px !important;
  }
}
.filter-category-mobile {
  color: #ffffff !important;
  font-weight: bold !important;
}
.accordion-heading-button {
  border: none !important;
  border-radius: 0px !important;
  color: #ffffff;
  width: 100%;
}
.accordion-heading-button-desktop {
  border: none !important;
  border-radius: 0px !important;
  color: #ffffff;
  width: 100%;
}
.accordion-heading-button:not(.collapsed) {
  background-color: #132d3b !important;
}
.accordion-heading-button:not(.collapsed) .rotate-icon {
  transform: rotate(180deg);
}
.accordion-heading-button-desktop:not(.collapsed) .rotate-icon {
  transform: rotate(180deg);
}
.accicon {
  float: right;
}
.collapse-user-details-header {
  width: 100% !important;
  border: none !important;
}
.autovip-checkin:hover {
  background-color: #5b5b5b !important;
}
.autovip-checkin {
  background-color: #313131 !important;
  color: #ffffff !important;
  padding: 0.5rem 0.7rem !important;
  border: 1px solid #313131 !important;
  border-radius: 5px !important;
}
.autovip-checkin-mobile {
  background-color: #3a373a !important;
  color: #ffffff !important;
  padding: 4px 8px !important;
  margin-left: 10px !important;
  border: 1px solid #151515 !important;
  border-radius: 5px !important;
}

.dropdown-wrapper {
  position: relative;
  display: inline-block;
}
.podSession-subjects {
  color: #fafafa !important;
  background: #2c2c2c !important;
  padding: 4px 4px 4px 4px;
  height: 38px;
  border-radius: 4px;
}
.podSession {
  color: #2c2c2c !important;
  background: #ffff !important;
  padding: 4px 8px 4px 4px;
  border-radius: 2px;
}
</style>
