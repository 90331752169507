<template>
  <div>
    <div
      class="modal fade"
      id="sessionInfoModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div
        class="modal-dialog modal-dialog-slideout modal-md"
        role="document"
        style="width: 540px !important"
      >
        <div class="modal-content">
          <div class="b-modal-header">
            <nav class="navbar fixed-top">
              <span class="close" data-dismiss="modal" @click="CloseModal()">
                <i class="modal-close pr-2">
                  <img
                    class="mt-2"
                    :src="getIcon('backButtonModal.svg')"
                    alt="chevron-left-solid"
                    style="width: 35px !important; height: 35px !important"
                  />
                </i>
              </span>
            </nav>
          </div>
          <div class="modal-body text-left p-0">
            <img :src="getImage(img)" class="w-100 h-auto" alt="Modal image" />
            <div class="mod-btn">
              <p
                class="moduleAct font-weight-bold py-1 px-3 font-18"
                style="height: 30px"
              >
                <a>
                  <span class="text-dark os-regular">{{
                    eventInfo.sessionData &&
                    eventInfo.sessionData.public_sale == true
                      ? "Public"
                      : "Private"
                  }}</span></a
                >
              </p>
            </div>
            <div
              class="pt-2 pr-5 pb-5 pl-5"
              style="background-color: #0e0e0e !important"
            >
              <h3
                v-if="
                  eventInfo.sessionData && eventInfo.sessionData?.title_name
                "
                class="modal-heading text-white font-36 font-md-43 d-flex align-content-center px-0 pb-0"
              >
                {{ eventInfo.sessionData?.title_name }}
              </h3>

              <div class="py-0">
                <p
                  class="pr-2 font-18 mb-6 os-regular"
                  v-if="eventInfo && eventInfo.start"
                >
                  <img
                    :src="getIcon('calendar-alt-solid.svg')"
                    alt="calendar"
                  />
                  {{ timeConvert(eventInfo.start.split("T")[1]) }} |
                  {{ eventInfo.start.split("T")[0] }}
                  <span class="text-capitalize"></span>
                </p>
              </div>
              <span
                @click="successfulCancelled"
                class="cancel-session-style mt-8 mb-8 p-2 os-regular"
              >
                <a class="os-regular" style="color: white !important"
                  >Cancel show</a
                >
              </span>
              <div
                class="mt-24 mb-20 pl-24 pr-24 pt-24 pb-24"
                style="background-color: #151515 !important"
              >
                <div class="d-flex justify-content-between">
                  <div class="available-seats-style p-0 os-regular">
                    <p>Available seats</p>
                    <p class="seat-record-available">
                      {{ eventInfo.sessionData.seats_reserved }} of
                      {{ eventInfo.sessionData.max_seats }} seats
                    </p>
                  </div>
                  <div class="pod-id-style font-14 os-regular">
                    <div>POD-{{ lastTwoDigits }}</div>
                  </div>
                </div>

                <div class="seating-chart pb-40">
                  <div
                    v-for="(seat, index) in maxSeats"
                    :key="index"
                    :class="{
                      reserved: index < seatsReserved,
                      available: index >= seatsReserved,
                    }"
                    class="seat-box"
                  ></div>
                </div>
              </div>
              <div>
                <div style="background-color: #151515 !important">
                  <p class="font-16 font-md-19 font-weight-bold pl-24 pt-24">
                    Participants:
                  </p>

                  <div
                    v-for="(participant, i) in paginatedParticipants"
                    :key="i"
                  >
                    <hr />

                    <div class="d-flex justify-content-between pl-24 pr-24">
                      <div class="col-12 col-md-6">
                        <p class="font-16 os-bold">
                          {{ participant.first_name }}
                          {{ participant.last_name }}
                        </p>
                        <!-- <p class="d-inline font-14">{{ participant.role }}</p> -->
                        <p class="font-14" v-if="participant.transaction_email">
                          {{ participant.transaction_email }}
                        </p>
                      </div>
                      <div class="">
                        <template v-if="participant.checked_in">
                          <p
                            class="font-14 text-white checkin-style text-capitalize mt-3 os-bold"
                          >
                            Checked in
                          </p>
                        </template>
                        <template v-else>
                          <p class="font-14 text-success checkin-style">
                            Reserved
                          </p>
                        </template>
                      </div>
                      <!-- <div v-if="!participant.checked_in" class="col-12 col-md-2">
                      <v-popover> -->
                      <!-- This will be the popover target (for the events and position) -->
                      <!-- <button class="font-24">
                          <img src="../../assets/icons/three-dots.svg" alt="" />
                        </button> -->
                      <!-- This will be the content of the popover -->
                      <!-- <div
                          slot="popover"
                          v-close-popover
                          class="text-left font-weight-bold"
                          @click="
                            deleteParticipant(
                              participant.session_id,
                              participant.ticket_id
                            )
                          "
                        >
                          <a>Cancel reservation</a>
                        </div>
                      </v-popover>
                    </div> -->
                    </div>
                  </div>
                  <v-pagination
                    v-if="paginatedParticipants.length > 7"
                    class="pt-55 pb-55 table-pagination"
                    v-model="page"
                    :length="totalPages"
                    circle
                    dark
                    :total-visible="7"
                    @input="updatePagination"
                  ></v-pagination>
                </div>

                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade px-0"
      id="remove_session"
      data-backdrop="exampleModalLabel"
      data-keyboard="false"
      tabindex="-1"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered w-custom"
        style="max-width: 716px; max-height: 344px"
      >
        <div class="modal-content animate-bottom br-18">
          <div
            class="p-0 modal-body"
            style="border: 0.5px solid #0b5786 !important; border-radius: 15px"
          >
            <div
              class="d-flex flex-column text-center"
              style="
                padding-bottom: 4px !important;
                padding-right: 36px !important;
                padding-left: 34px !important;
                padding-top: 21px;
              "
            >
              <p
                class="font-20 kallisto-bold mb-2 text-left"
                style="color: #ffc627"
              >
                Cancel show
              </p>
              <p class="font-14 font-md-18 font-weight-bold my-3 mb-3">
                Are you sure you want to cancel :
                {{ eventInfo.sessionData?.title_name }}?
              </p>
              <div class="d-flex justify-content-center">
                <div>
                  <button
                    data-dismiss="modal"
                    class="reset-button-filter px-14 py-2"
                  >
                    No
                  </button>
                </div>
                <div>
                  <button
                    data-dismiss="modal"
                    class="continue-btn os-bold mb-32 px-4"
                    @click="deleteSession"
                  >
                    Yes, cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade px-0"
      id="success_modal"
      data-backdrop="exampleModalLabel"
      data-keyboard="false"
      tabindex="-1"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered w-custom"
        style="max-width: 716px; max-height: 344px"
      >
        <div class="modal-content animate-bottom br-18">
          <div
            class="p-0 modal-body"
            style="border: 0.5px solid #0b5786 !important; border-radius: 15px"
          >
            <div
              class="d-flex flex-column text-center"
              style="padding: 24px !important"
            >
              <img
                :src="getIcon('check-solid.svg')"
                alt="checked"
                class="checkin-complete mt-5"
              />
              <p class="font-14 font-md-18 font-weight-bold my-3 mb-38">
                Show successfully cancelled
              </p>
              <div>
                <button
                  data-dismiss="modal"
                  class="continue-btn os-bold mb-32 px-4"
                  @click="handlerefreshParent()"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  props: ["eventInfo"],
  data() {
    return {
      page: 1,
      itemsPerPage: 10,
      moduleAct: "Session",
      img: "box.png",
      title: "Food Web: Act 2",
    };
  },
  methods: {
    updatePagination(page) {
      this.page = page;
    },
    CloseModal() {
      this.$emit("closeModal");
    },
    getImage(img) {
      return require("@/assets/images/alien_1.jpg");
    },
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },
    getTime() {
      return this.eventInfo.start_timestamp.substr(
        0,
        this.eventInfo.start_timestamp.indexOf("T")
      );
    },
    getDate() {},
    closeSessionInfoModal() {},
    timeConvert(time) {
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? "am" : "pm"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
    deleteParticipant(sessionId, orderId) {
      $("#sessionInfoModal").modal("hide");

      var data = {
        sessionId: sessionId,
        orderId: orderId,
      };
      this.$store.dispatch("deleteCalendarSessionOrder", data).then(() => {
        this.$toasted.show("Reservation deleted successfully", {
          position: "top-right",
          duration: 5000,
        });
      });
    },
    handlerefreshParent() {
      this.$emit("handleRefresh");
    },
    successfulCancelled() {
      $("#sessionInfoModal").modal("hide");
      $("#remove_session").modal();
    },
    deleteSession() {
      var now = this;
      this.CloseModal();
      if (this.$store.state.calendarSessionOrders.length <= 0) {
        $("#remove_session").modal("hide");
        var data = {
          session_id: this.eventInfo.sessionData.session_id,
        };
        this.$store.dispatch("deleteCalendarSession", data).then(() => {
          let myData = {
            selected_site_id: this.$store.state.selected_site_id,
            start_time: this.$store.state.calDates.lastStart.date,
            end_time: this.$store.state.calDates.lastEnd.date,
          };

          this.$store
            .dispatch("setCalendarSessionsWithStartAndEndDate", myData)
            .then(() => {
              now.handlerefreshParent();

              $("#success_modal").modal();
            });
        });
      } else {
        this.$toasted.show("Please delete all reservations", {
          position: "top-center",
          duration: 5000,
        });
      }
    },
  },

  computed: {
    lastTwoDigits() {
      const podId = this.eventInfo.sessionData?.pod_id;
      return podId?.split("-").pop();
    },
    seatsReserved() {
      return this.eventInfo.sessionData.seats_reserved;
    },
    maxSeats() {
      return this.eventInfo.sessionData?.max_seats;
    },
    paginatedParticipants() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.$store.state.calendarSessionOrders.slice(start, end);
    },
    totalPages() {
      return Math.ceil(
        this.$store.state.calendarSessionOrders.length / this.itemsPerPage
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.seat-record-available {
  display: inline-flex;
  height: 28px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 0.5px solid #0b5786;
  background: #393939;
  font-size: 14px;
  color: var(--White, #ecedee);
}
.checkin-style {
  background: #242424;
  display: flex;
  width: 95px;
  border-radius: 20px;
  justify-content: center;
  padding: 8px;
  line-height: 1;
}
.modal.fade.show .modal-dialog.modal-dialog-slideout {
  margin-top: 0px !important;
}
.available-seats-style {
  color: #d0d0d0;
}
.cancel-session-style {
  border-radius: 4px;
  background: var(--Grey, #2c2c2c);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.8);
}
.seats-available {
  background: #707070;
  padding: 4px;
  color: #ffc627;
}
.pod-id-style {
  background: #707070;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  color: #ffff;
  border-radius: 20px;
  margin-right: 25px;
  position: relative;
  height: 27px !important;
  width: 83px !important;
}
.seating-chart {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding-right: inherit;
}

.seat-box {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid #000;
}

.seat-box.reserved {
  background-color: #007dc5;
}

.seat-box.available {
  background-color: #707070;
}
.session-info-public {
  background-color: white !important;
  color: black;
}
.modal-dialog-slideout {
  min-height: 100%;
  margin: 0 0 0 auto;
  background: #fff;
}
.modal.fade .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(100%, 0) scale(1);
  transform: translate(100%, 0) scale(1);
}
.modal.fade.show .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  display: flex;
  align-items: stretch;
  -webkit-box-align: stretch;
  height: 100%;
}
.modal-dialog-slideout .modal-content {
  border: 0;
}
.modal-dialog-slideout .modal-header,
.modal-dialog-slideout .modal-footer {
  height: 69px;
  display: block;
}
.modal-dialog-slideout .modal-header h5 {
  float: left;
}
.reset-button-filter {
  background: #313131 !important;
  color: #f1f1f1;
  border-radius: 8px;
  padding-right: 28px;
  padding-left: 28px;
  margin-right: 16px;
}

.modal {
  width: 102% !important;
}
.modal-md {
  width: 50%;
}
@media only screen and (max-width: 991px) {
  .modal-md {
    width: 100%;
  }
  .showinDesktop {
    display: none;
  }
}
@media only screen and (min-width: 991px) {
  .showinMobile {
    display: none;
  }
}

.modal-header {
  background: rgb(0, 0, 0.2);
  padding-top: 10px;
  padding-bottom: 10px;
}

.modal-body {
  color: white;
  background: #151515;
}
.modal-heading {
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 700;
  color: #000011;
}

.mdal-list {
  font-size: 14px;
}
.modal-p {
  font-size: 14px;
}

.close,
.close:hover {
  font-size: 21px;
  line-height: 1;
  color: #ffffff;
  font-weight: 400;
  opacity: 1 !important;
  cursor: pointer;
}
.modal-nav {
  padding-left: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: rgb(0 0 0 / 32%);
}
.modal-dialog {
  max-width: 600px;
}
.mod-btn {
  position: absolute;
  margin-top: -16px;
  left: 30px;
}
.moduleAct {
  background: #fafafa;
  color: #151515;
}

hr {
  background-color: white;
}

.participants-color-reserved {
  width: 17px;
  height: 17px;
  background: #ffc627 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  margin-right: 4px;
}
.participants-color-checkedin {
  width: 17px;
  height: 17px;
  background: #78be20 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  margin-right: 4px;
}
.reserved-dot {
  width: 9px;
  height: 9px;
  background: #ffc627 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50px;
  margin-top: 6px;
  margin-right: 3px;
}

a {
  cursor: pointer;
}
</style>
